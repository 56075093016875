body {
  font-family: 'Lato', sans-serif;
}

.catalog {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #ffffff00;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
